import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Register from '../Registration/register';
import { OAuthButtonThemes } from '../Registration/OAuth2/Shared/oauth-button';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import ApiAnalytics from '../../api/api-analytics';
import UriHelper from '../../lib/uri-helper';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/Account/selectors';
import SoarHelper from '../../lib/soar-helper';

export const JoinPageParameters = [
    { value: '1', label: 'Terrain' },
    { value: '2', label: 'Climate' },
];

const Connect = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [hasPageJustLoaded, setHasPageJustLoaded] = useState(true);
    const [email, setEmail] = useState<string>('');
    const [label, setLabel] = useState<string | undefined>(undefined);

    const handlePageLoadedWhileAlreadyLoggedIn = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'User already logged in', label);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const handleSuccessfulRegistration = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'Registered', label);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    useEffect(() => {
        setTimeout(() => {
            setHasPageJustLoaded(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameter = urlParams.get('q');
        const label = JoinPageParameters.find((param) => param.value === parameter)?.label;

        setLabel(label);
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'View', label);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            if (hasPageJustLoaded) {
                handlePageLoadedWhileAlreadyLoggedIn();
            } else {
                handleSuccessfulRegistration();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const renderCarousel = () => {
        switch (label) {
            case 'Terrain':
                return (
                    <ContentCarousel>
                        <ContentCarouselItem src="/assets/join-page/asset1-terrain.png" />
                        <ContentCarouselItem src="/assets/join-page/asset2-terrain.png" />
                        <ContentCarouselItem src="/assets/join-page/asset3-terrain.png" />
                    </ContentCarousel>
                );
            case 'Climate':
                return (
                    <ContentCarousel>
                        <ContentCarouselItem src="/assets/join-page/asset1-climate.png" />
                        <ContentCarouselItem src="/assets/join-page/asset2-climate.png" />
                        <ContentCarouselItem src="/assets/join-page/asset3-climate.png" />
                    </ContentCarousel>
                );
            default:
                return (
                    <ContentCarousel>
                        <ContentCarouselItem src="/assets/join-page/asset1.png" />
                        <ContentCarouselItem src="/assets/join-page/asset2.png" />
                        <ContentCarouselItem src="/assets/join-page/asset3.gif" />
                    </ContentCarousel>
                );
        }
    };

    const isMobile = SoarHelper && SoarHelper.isMobile();

    return (
        <ConnectPage isMobile={isMobile}>
            <ConnectContainer>
                <ConnectContent>
                    <ContentHeader>Find the maps you are looking for, fast!</ContentHeader>
                    <ContentFeatures>
                        <ContentFeature>World's fastest growing collection of online maps</ContentFeature>
                        <ContentFeature>Over 641,035 maps&hellip; and growing!</ContentFeature>
                        <ContentFeature>100% FREE</ContentFeature>
                    </ContentFeatures>
                    <ContentTrust>
                        <ContentTrustHeader>TRUSTED BY</ContentTrustHeader>
                        <ContentBelievers>
                            <ContentBeliever
                                src="assets/join-page/nyt-logo-square.jpg"
                                alt="The New York Times"
                                title="The New York Times"
                            />
                            <ContentBeliever src="assets/join-page/bbc-logo.svg" alt="BBC" title="BBC" />
                            <ContentBeliever
                                src="assets/join-page/ft-logo.svg"
                                alt="Financial Times"
                                title="Financial Times"
                            />
                        </ContentBelievers>
                    </ContentTrust>
                    {renderCarousel()}
                </ConnectContent>
                <ConnectAccess>
                    <ConnectAccessHeader>Instant Access To Online Maps 👇</ConnectAccessHeader>
                    <ConnectAccessContent>
                        <ConnectAccessFormContainer>
                            <Register
                                email={email}
                                onEmailChange={(email) => setEmail(email)}
                                onClose={() => ''}
                                handleClickLogin={() => ''}
                                onRegisterSuccess={() => {
                                    null;
                                }}
                                hideFooter={true}
                                theme={OAuthButtonThemes.LIGHT}
                                submitText="Start Exploring Now!"
                            />
                        </ConnectAccessFormContainer>
                    </ConnectAccessContent>
                </ConnectAccess>
            </ConnectContainer>
        </ConnectPage>
    );
};

export default Connect;

const ConnectContent = styled.div`
    flex: 1 1 65%;
    padding: 0 20px;
`;

const ContentHeader = styled.h1`
    font-weight: 700;
    font-size: 4rem;
`;

const ContentFeatures = styled.ul`
    list-style: none;
    margin: 50px 65px 0;
    padding: 0;

    font-size: 1.75rem;
`;
const ContentFeature = styled.li`
    margin-bottom: 20px;

    &:before {
        content: '✓';
        font-weight: 700;

        margin-right: 10px;
    }
`;

const ContentTrust = styled.div``;
const ContentTrustHeader = styled.h2`
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 20px;
`;

const ContentBelievers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const ContentBeliever = styled.img`
    width: auto;
    height: 50px;
    margin: 0 10px;
`;

const ContentCarousel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px 20px;
`;

const ContentCarouselItem = styled.img`
    width: 30%;
    height: auto;
`;

const ConnectAccess = styled.div`
    flex: 1 0 35%;
    display: flex;
    flex-direction: column;

    border: 1px solid ${(props) => props.theme.color.lightGray};
    border-radius: 4px;
    overflow: hidden;
`;

const ConnectAccessHeader = styled.h2`
    padding: 20px 0;

    font-size: 1.65rem;
    font-weight: 700;

    color: ${(props) => props.theme.color.white};

    background-color: ${(props) => props.theme.color.black};
`;

const ConnectAccessContent = styled.div`
    padding: 10px 25px;
`;

const ConnectAccessFormContainer = styled.div``;

const ConnectContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin: 16px auto;
    width: 98vw;
    max-width: 1200px;
    gap: 10px;
`;

const ConnectPage = styled.div<{ isMobile: boolean }>`
    margin-top: ${(props) => (!props.isMobile ? `70px` : null)};
    height: ${(props) => (!props.isMobile ? `calc(100vh - 70px)` : `100vh`)};
    padding-top: 35px;
    background-color: ${(props) => props.theme.color.white};

    position: relative;

    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }

    ${(props) => {
        if (props.isMobile) {
            return `
            ${ConnectContainer} {
                flex-direction: column;

                ${ConnectContent} {
                    padding: 0 10px;

                    ${ContentHeader} {
                        font-size: 2.5rem;
                    }

                    ${ContentFeatures} {
                        margin: 30px 10px 0;

                        ${ContentFeature} {
                            font-size:1rem;
                        }
                    }

                    ${ContentBelievers} {
                        flex-direction: column;
                        gap: 8px;
                        align-items: center;
                    }
                }
            }`;
        }
        return;
    }}
`;
