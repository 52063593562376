import { LayersControl, TileLayer as LeafletTileLayer } from 'react-leaflet';
import Constants from '../../../constants';
import { useBasemap } from '../../../store/Map/Basemap/use-basemap';
import { Basemap } from '../../../store/Map/Basemap/model';

const MobileBasemap = () => {
    const { basemap } = useBasemap();

    return (
        <LayersControl position="bottomright">
            <LayersControl.BaseLayer name={Basemap.OPEN_ELEVATION} checked={basemap === Basemap.OPEN_ELEVATION}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={13} url={Constants.MAPS.OSM_TOPO} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OPEN_STREETMAPS} checked={basemap === Basemap.OPEN_STREETMAPS}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={Constants.MAPS.OSM_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_SATELLITE} checked={basemap === Basemap.GOOGLE_SATELLITE}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_SATELLITE} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_HYBRID} checked={basemap === Basemap.GOOGLE_HYBRID}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_HYBRID} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.GOOGLE_STREET} checked={basemap === Basemap.GOOGLE_STREET}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.DARK_BLANK} checked={basemap === Basemap.DARK_BLANK}>
                <LeafletTileLayer url="/assets/basemap/black-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.WHITE_BLANK} checked={basemap === Basemap.WHITE_BLANK}>
                <LeafletTileLayer url="/assets/basemap/white-pixel.png" />
            </LayersControl.BaseLayer>
        </LayersControl>
    );
};

export default MobileBasemap;
