export enum Basemap {
    OPEN_ELEVATION = 'Open Elevation',
    GOOGLE_SATELLITE = 'Google Satellite',
    GOOGLE_HYBRID = 'Google Hybrid',
    GOOGLE_STREET = 'Google Street',
    OPEN_STREETMAPS = 'OpenStreetMaps',
    DARK_BLANK = 'Dark Blank',
    WHITE_BLANK = 'White Blank',
}

export const getBasemap = (value: string): Basemap | undefined => {
    const key = Object.keys(Basemap).find((key) => Basemap[key] === value);
    if (!key) return undefined;
    return Basemap[key];
};
