import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import './map-scale-control.css';

const createMapScaleControl = (props) => {
    const mapControl = new L.Control.BetterScale(props);
    return mapControl;
};

const MapScaleControl = createControlComponent(createMapScaleControl, null);

export default MapScaleControl;


L.Control.BetterScale = L.Control.extend({
    options: {
        position: "bottomright",
        maxWidth: 110,
        updateWhenIdle: !1,
    },
    onAdd: function (t) {
        this._map = t;
        const e = "leaflet-control-better-scale",
            i = L.DomUtil.create("div", e),
            n = this.options,
            s = L.DomUtil.create("div", e + "-ruler", i);
        return this._addScales(n, e, s), this.ScaleContainer = i, t.on(n.updateWhenIdle ? "moveend" : "move", this._update, this), t.whenReady(this._update, this), i
    },
    onRemove: function (t) {
        t.off(this.options.updateWhenIdle ? "moveend" : "move", this._update, this)
    },
    _addScales: function (t, e, s) {
        this._iScaleMetric = L.DomUtil.create("div", e + "-ruler-block " + e + "-metric", s);
        this._iScaleMetricLabel = L.DomUtil.create("div", e + "-label", this._iScaleMetric);

        this._iScaleImperial = L.DomUtil.create("div", e + "-ruler-block " + e + "-imperial", s);
        this._iScaleImperialLabel = L.DomUtil.create("div", e + "-label", this._iScaleImperial);
    },
    _update: function () {
        const t = this._map.getBounds(),
            e = t.getCenter().lat,
            i = 6378137 * Math.PI * Math.cos(e * Math.PI / 180),
            n = i * (t.getNorthEast().lng - t.getSouthWest().lng) / 180,
            o = this._map.getSize(),
            s = this.options;
        let a = 0;
        o.x > 0 && (a = n * (s.maxWidth / o.x)), this._updateScales(s, a)
    },
    _updateScales: function (t, e) {
         e && this._updateMetric(e), e && this._updateImperial(e)
    },
    _updateMetric: function (t) {
        let e, i, n;
        const a = t,
            h = this._iScaleMetricLabel,
            s = this._iScaleMetric;

            a > 500 ? (e = a / 1000, i = this._getRoundNum(e),  s.style.width = this._getScaleWidth(i / e) + "px",  h.innerHTML = i + "km"): (n = this._getRoundNum(a),  s.style.width = this._getScaleWidth(n / a) + "px",  h.innerHTML = n + "m")
    },
    _updateImperial: function (t) {
        let e, i, n;
        const a = 3.2808399 * t,
            h = this._iScaleImperialLabel,
            s = this._iScaleImperial;

            a > 2640 ? (e = a / 5280, i = this._getRoundNum(e), s.style.width = this._getScaleWidth(i / e) + "px",  h.innerHTML = i + "mi") : (n = this._getRoundNum(a), s.style.width = this._getScaleWidth(n / a) + "px", h.innerHTML = n + "ft");
    },
    _getScaleWidth: function (t) {
        return Math.round(this.options.maxWidth * t) - 10
    },
    _getRoundNum: function (t) {
        if (t >= 2) {
            const e = Math.pow(10, (Math.floor(t) + "").length - 1);
            let i = t / e;
            return i = i >= 10 ? 10 : i >= 5 ? 5 : i >= 3 ? 3 : i >= 2 ? 2 : 1, e * i
        }
        return (Math.round(100 * t) / 100).toFixed(1)
    },
});

L.control.betterscale = function (options) {
    return new L.Control.BetterScale(options)
};