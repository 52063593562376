import L, { LatLng, LatLngBounds, Polygon } from 'leaflet';

import { Config } from '../store/App/model';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export enum SubDomainType {
    Free = 'free',
    Basic = 'basic',
    Pro = 'pro',
    Enterprise = 'enterprise',
}

export enum ListingType {
    IMAGE = 'IMAGE',
    TILE_LAYER = 'TILE_LAYER',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    WMS = 'WMS',
    WMTS = 'WMTS',
    COG = 'COG',
    ORDER = 'ORDER',
    EXTERNAL_TILE_LAYER = 'EXTERNAL_TILE_LAYER',
}

export enum AnalyticsAction {
    VIEW = 'VIEW',
    DOWNLOAD = 'DOWNLOAD',
    SEARCH = 'SEARCH',
}

export enum AnalyticsNote {
    MY_IMAGERY = 'MY_IMAGERY',
    BROWSE_PAGE = 'BROWSE_PAGE',
    DISCOVER = 'DISCOVER',
    SUBDOMAIN_DRAW = 'SUBDOMAIN_DRAW',
    YOUR_MAPS = 'YOUR_MAPS',
    FEATURED_DRAW = 'FEATURED_DRAW',
    STOARY = 'STOARY',
    DASHBOARD_TASK = 'DASHBOARD_TASK',
    CLUSTER = 'CLUSTER',
    USER_PROFILE = 'USER_PROFILE',
    SHARE = 'SHARE',
    SEARCH = 'SEARCH',
    BOOKMARKS = 'BOOKMARKS',
}

export enum Permission {
    None = -1,
    Read = 0,
    Write = 1,
    Review = 2,
    Admin = 3,
    Owner = 4,
}

export enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

export enum Review {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum SatelliteProvider {
    SINERGISE = 'SINERGISE',
}

export interface PrimaryCategory {
    value: string;
    label: string;
}

export const listingCategoryOptions: PrimaryCategory[] = [
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'climate', label: 'Climate' },
    { value: 'earth-art', label: 'Earth Art' },
    { value: 'economic', label: 'Economic' },
    { value: 'environment', label: 'Environment' },
    { value: 'geology', label: 'Geology' },
    { value: 'historical', label: 'Historical' },
    { value: 'imagery', label: 'Imagery' },
    { value: 'landcover', label: 'Landcover' },
    { value: 'nautical', label: 'Nautical' },
    { value: 'political', label: 'Political' },
    { value: 'radar', label: 'Radar' },
    { value: 'terrain', label: 'Terrain' },
    { value: 'topographical', label: 'Topographical' },
    { value: 'transport', label: 'Transport ' },
    { value: 'urban', label: 'Urban' },
];

// GET v1/listings/
export interface ListingDTO {
    id: number;
    listingType: ListingType;
    geometryWKT: string;
    metadata: string;

    title: string;
    seoTitle?: string;
    description: string;
    tags: string[];
    categories: string[];

    filename: string;
    filehash?: string;
    tileUrl: string;
    minZoom?: number; //not used

    totalLikes: number;
    totalComments?: number;
    totalBookmarks?: number;
    totalViews?: number;
    outsideOfAoi?: boolean;

    //user
    userId: string;
    userName: string;
    avatarUrl: string;
    reputable?: boolean;
    communityModerator?: boolean;

    createdAt: number;
    updatedAt?: number;
    public?: boolean;

    //ListingDetailsDTO
    similar?: ListingDTO[];

    //ListingForOwnerOrAdminDTO
    filesize?: number;
    review?: Review;
    reviewSoar?: Review;
    error?: string;
    userEmail?: string;
    feedback?: string;
    processing?: boolean;
    rejectionReasons?: ListingReviewRejectionReason[];

    //calculated values
    boundingBox: LatLngBounds;
    dateUploaded?: Date;

    //for drone // TODO double check this at end when removed drones
    position?: LatLng;
    distoredGeometry?: string;
    priceUsd: number;
    urlFullRes?: string;

    // WMS Layer properties
    layerName?: string;
    serverUrl?: string;
    layerImageFormat?: string;
    layerSpeedKbps?: number;
    layerSrs?: string;
    layerVersion?: string;
    style?: string;

    // WMTS Layer properties
    tileMatrixSet?: ListingTileMatrixSet;

    // TMS Layer properties
    tms?: boolean;
    maxZoom?: number;

    // STAC Layer properties
    geometryGeoJson?: string;
    bboxWKT?: string;

    // Attached files
    files?: ListingFile[];
}

export interface ListingFile {
    fileName: string;
    fileSize: number;
    created: string;
    description: string;
    id: number;
    listingId: number;
    type: 'LEGEND' | 'DOC' | 'OTHER' | 'UNKNOWN';
    title: string;
    url: string;
}

export interface ListingTileMatrixSet {
    crs: string;
    identifier: string;
    matrices: ListingTileMatrix[];
}

export interface ListingTileMatrix {
    identifier: string;
    matrixHeight: number;
    matrixWidth: number;
    tileHeight: number;
    tileWidth: number;
    topLeftPoint: string;
}

export interface ListingAttachment {
    created?: string;
    id: number;
    listingId: number;
    type: 'LEGEND' | 'DOC' | 'OTHER' | 'UNKNOWN';
    url?: string;
    title: string;
    description: string;
}

export interface ListingReviewRejectionReason {
    reason: string;
    feedback: string;
    blogLink?: string;
}

export interface CSWHistoricalLayer {
    orbitId: number;
    sceneId: number;
    stripId: number;
    mirrorOffNadir: string;
    date: Date;
    cloudPercentage: number;
    boundingBox: LatLng[];
    previewUrl: string;
}

export interface TaskPricePreview {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface CSWCollectionRequestCost {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency?: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface CSWHistoricalRequestCost {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency?: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface OrderDTO {
    id: number;
    orderNumber: string;
    supplierCode: string;
    productId: number;
    productCode: string;
    productOption: string;
    deliveryFormat: string;
    countryCode: string;
    areaInKm: number;
    archiveSelectedAreaInKm: number;
    delayInSeconds: number;
    requestedDate: Date;
    created: Date;
    dueBy: Date;
    deliveryFormatNote: string;

    company: string;
    payment: {
        amount: number;
        pricePerKm: number;
        paidAt: Date;
        paymentCaptured: boolean;
        payment: boolean;
        currency: string;
        tax: number;
        paidByStripe: number;
        paidByOtherMethod: number;
        paidByVoucher: number;
        receiptUrl?: string;
        invoiceUrl?: string;
    };

    provider: string;
    geometryWKT: string;

    createdAt: Date;
    files: TaskFile[];
    status: CSWTaskStatus;
    effectiveAreaInKm: number;
    noteRejected?: string;
}

export interface OrderDetailsDTO extends OrderDTO {
    previewUrl: string;
    url: string;
    tileUrl: string;
    boundingBox: LatLngBounds;
}

export interface CSWTask {
    geometryWKT: string;
    id: number;
    orderNumber: string;
    deliverableType: string;
    effectiveAreaInKm: number;
    areaInKm: number;
    paidByStripe: number;
    paidByVoucher: number;
    paidByOtherMethod: number;
    additionalTax: number;
    total: number;
    currency?: string;
    totalTax?: number;
    status: CSWTaskStatus;
    createdAt: Date;
    company: string;
    provider: string;
    pricePerKm: number;
    boundingBox: LatLngBounds;
    receiptUrl?: string;
    invoiceUrl?: string;
    previewUrl?: string;
    url?: string;
    tileUrl?: string;
    noteRejected?: string;
    note?: string;
    files: TaskFile[];

    title?: string;

    owner?: { username?: string; userId?: string };

    username?: string;
    userId?: string;
}

export interface TaskFile {
    type: string;
    description: string;
    id: string;
    fileName: string;
    contentType: string;
    fileSize: number;
    created: string;
    downloadUrl?: string;
}

export interface OrderForPayment {
    order: OrderDTO;
    preview: TaskPricePreview;
}

export enum CSWTaskStatus {
    CREATED = 'CREATED',
    REVIEW_SOAR = 'REVIEW_SOAR',
    REVIEW_PROVIDER = 'REVIEW_PROVIDER',
    COLLECTION = 'COLLECTION',
    QA_SOAR = 'QA_SOAR',
    DELIVERED = 'DELIVERED',
    REJECTED = 'REJECTED',
    REVIEW_SUPPLIER = 'REVIEW_SUPPLIER',
}

export interface ListingMetadata {
    title: string;
    tags: string[];
    description: string;
    categories: string[];
    tc?: boolean;
}

export const CRSDefs = {
    'EPSG:3395': L.CRS.EPSG3395,
    'EPSG:3857': L.CRS.EPSG3857,
    'EPSG:4326': L.CRS.EPSG4326,
};

export interface UserDTO {
    firstName?: string;
    lastName?: string;
    userId: string;
    email?: string;
    createdAt?: number;
    bio?: string;
    name?: string;
    avatarUrl?: string;
    listings?: [any];
    twitterUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    websiteUrl?: string;
    userSoarProfileUrl?: string;
    billingInfo?: BillingInfoDTO;
    domains?: DomainPermissionDTO[];

    linkedinUrl?: string;
    longDescription?: string;

    reputable?: boolean;
    communityModerator?: boolean;
    roles?: string[];

    points?: number;
}

export interface DomainPermissionDTO {
    domain: string;
    permission: Permission;
}

export interface BillingInfoDTO {
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    country?: string;
    city?: string;
    postCode?: string;
    phone?: string;
    paypal?: string;
}

export interface UploadCredentialsDTO {
    stsCredentials: {
        securityToken: string;
        accessKeyId: string;
        accessSecretKey: string;
    };
    ossRegion: string;
    bucketName: string;
    filename: string;
    path: string;
    distoredGeometry?: Polygon;
    listingId?: number;
}

// GET /v1/platform/prices
export interface PriceDTO {
    priceUsd: number;
    feeUsd: number;
}

export interface DomainUserDTO {
    email: string;
    permissions: Permission;
    domainName?: string;
    name?: string;
    userId?: string;
    invitationId?: string;
}

export interface ResultDTO {
    success: boolean;
}

export interface DomainDTO {
    billingInfo: BillingInfoDTO;
    config: Config;
    name: string;
    createdAt: Date;
    invoices: InvoiceDTO[];
    subscription: SubscriptionDTO;
    type: string;
    status: string;
    tileConsumed: number;
}

interface SubscriptionDTO {
    cardBrand: string;
    cardExpMonth: number;
    cardExpYear: number;
    cardLast4: number;
    nextPaymentAt: Date;
    total: number;
    cancelAt?: Date;
    trialEndAt?: number;
    currency?: string;
}

export interface InvoiceDTO {
    amount: number;
    invoiceId: string;
    paidAt: Date;
    url: string;
}

export interface DownloadLinkDTO {
    presignedUrl: string;
    filename: string;
}

export interface ListingHash {
    latlng: LatLng;
    listingId: number;
}

export interface TileLayerHash {
    latlngBounds: LatLngBounds;
    listingId: number;
    author?: string;
    title?: string;
}

export interface ImageMetadata {
    title?: string;
    description?: string;
    tags?: string[];
    categories?: string[];
    streetAddress?: string;
    make: string;
    model: string;
    focalLength?: string;
    apertureValue?: string;
    fNumber?: string;
    shutterSpeed?: string;
    lightSource?: string;
    dateTaken?: string;
    dateUploaded?: string;
    altitude?: string;
}

export interface FileInfo {
    filehash: string;
    geometryWKT: string;
    geohash: string;
    exif: string;
    contentType: string;
    distoredGeometry: string;
}

export interface CreateSubdomainDTO {
    name: string;
    type: SubDomainType;
    countryCode?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    city?: string;
    address?: string;
    postCode?: string;
    phone?: string;
    stripeToken?: string;
    voucher?: string;
}

export interface SaleInfo {
    id: string;
    amount: number;
    fee: number;
    paidAt: Date;
    createdAt: Date;
    payoutId?: string;
    paidOutAt?: Date;
    type: string;
    listingId: string;
    currency: string;
    listing?: ListingDTO;
}

export interface PurchasedInfo {
    id: string;
    amount: number;
    paidAt: Date;
    createdAt: Date;
    receiptUrl: string;
    currency: string;
    items: PurchasedItemInfo[];
}

export interface PurchasedItemInfo {
    id: string;
    amount: number;
    paidAt: Date;
    createdAt: Date;
    ownerId: string;
    purchaseId: string;
    type: string;
    typeData: string;
    listingId?: string;
    currency: string;
}

export interface CountryInfo {
    country: string;
    countryCode: string;
}

export interface SubDomainPriceDTO {
    currency?: string;
    freeTrialInDays?: number;
    totalPrice: number;
    totalTax?: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
    durationInMonths?: number;
}

export interface CommentDTO {
    annotations?: any;
    text?: string | string[] | undefined;
    id?: string;
}

export interface ContactUsFields {
    email: string;
    message: string;
    subject: string;
    industry: string;
    requirements: string;
    geometry?: undefined | string;
    countryCode?: undefined | string;
    mobile?: undefined | string;
    listingId?: string | number;
}

export interface CommentResultsDTO {
    id: string;
    userId: string;
    userName: string;
    userAvatar: string;
    userReputable?: boolean;
    communityModerator?: boolean;
    listingId: number;
    text: string;
    annotations: string;
    createdAt: number;
    parentId?: string;
    updatedAt: number;
    vote: number;
    userVote: 'UP' | 'DOWN' | 'UNVOTE';
    children: CommentResultsDTO[];
}

export interface CommentReportDTO {
    id: string;
    userId: string;
    reason: string;
    message: string;
    createdAt: number;
    userEmail: string;
    review: 'PENDING' | 'APPROVED' | 'REJECTED';
    reviewedAt?: number;
    reviewedBy?: string;
    comment: CommentDTO;
}

export type AutocompleteType = 'ADDRESS' | 'LISTING' | 'SEARCH' | 'USER' | 'COORDINATE';

export interface AutocompleteDTO {
    type: AutocompleteType;
    title: string;
    description?: string;
    listingId?: number;
    geometryWKT?: string;
    extentWKT?: string;
    magicKey?: string;
    userId?: string;
    latlng?: LatLng;
}

export interface NotificationDTO {
    id: number;
    type: 'COMMENT_REPLY' | 'COMMENT';
    userName: string;
    userId: string;
    listingId: number;
    listingName: string;
    content: string;
    createdAt: number;
    commentId: string;
    viewedAt?: number;
}

export interface EmbedDTO {
    mapId: number;
    annotations?: string;
}

export interface SentinelImageDimension {
    widthPixels: number;
    heightPixels: number;
    resolution: number;
    isAvailable: boolean;
    maxPixels: number;
    quality: 'Best' | 'Good' | 'Low' | 'Poor';
}

export interface CreatedShortUrlDTO {
    key: string;
    url: string;
}

export interface ShortUrlDTO {
    url?: string;
    imageUrl?: string;
    title?: string;
    description?: string;
    domainName?: string;
    address?: string;
    date?: string;
}
